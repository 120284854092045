import { useState, useEffect } from 'react'
import { Subject, interval } from 'rxjs'
import { switchMap, filter } from 'rxjs/operators'
import { debounce, map } from 'rxjs/operators'
import { createContainer } from 'unstated-next'
import moment from 'moment'
import { history } from '../../infrastructure/navigation'
import { Guid } from '../../infrastructure/guid'
import { api } from '../../infrastructure/api'
import { Claims } from '../../infrastructure/signIn/models'
import { hasClaim, UserContextContainer } from '../../infrastructure/signIn/userContext'
import {
    MovementListItem, MovementType, StockFilters, StockProduct, DutyStatus, Company, Site, StockProjectionResult,
    MovementTypeNameFromMovementType, StockProjectionMode, StockSimulation, defaultMovementTypeSelectionOptions, MovementStatus,
    Counterparty
} from './stockModels'
import { hasFeature } from '../../infrastructure/feature'
import { t } from '../../infrastructure/i18nextHelper'
import { snackbars } from '../../infrastructure/snackbars'
import { StockFiltersContainer } from './filters/filtersStore'
import {
    DirectTransfer, Gains, GoodReceipt, GoodsTransfer, Losses, PurchaseOrder, PurchaseRelease, Rebranding, SalesOrder,
    SapFlowStepName, SapTypes, TransferDeliveryNote, TransferGoodsIssue, StockTransferOrder, TransferFlowType
} from '../common/sapHelper'

let inValue = 'in'
let outValue = 'out'

let constructSimulationQueryPart = (simulation: StockSimulation[]): string => {
    let query = ""
    for (let i = 0; i < simulation.length; i++)
        query += `stockSimulations[${i}].date=${simulation[i].date}&stockSimulations[${i}].value=${simulation[i].value}&stockSimulations[${i}].movementType=${simulation[i].movementType}&`
    return query
}

type StockFiltersProp = { stockFilters: StockFilters, stockSimulations: StockSimulation[] }
type StockRxFilters = StockFiltersProp & { stockMode: StockProjectionMode }
type MovementRxFilters = StockFiltersProp & {
    switchFilter: string,
    movementStatuses: MovementStatus[] | null,
    movementType: MovementType | null,
    meansOfTransportation: string[] | null,
    textFilter: string | null,
    warning: string | null,
    nomination: string | null,
    deal: string | null,
    sapTypes: SapTypes[],
}

let defaultStockProjectionResult: StockProjectionResult = {
    minVolumes: null,
    lowVolumes: null,
    highVolumes: null,
    maxVolumes: null,
    minDays: null,
    securityStockLines: null,
    lastCalibratedDate: null,
    values: null,
    lines: null
}

function useStockBoard() {
    let stockFiltersStore = StockFiltersContainer.useContainer()

    let [stockQuerySubject] = useState(new Subject<StockRxFilters>())
    let [movementQuerySubject] = useState(new Subject<MovementRxFilters>())
    let [showActualStockPopup, setShowActualStockDisplay] = useState<boolean>(false)
    let [switchFilter, setSwitchFilter] = useState<string>(inValue)
    let [movements, setMovements] = useState<MovementListItem[]>([])
    let [products, setProducts] = useState<StockProduct[]>([])
    let [sites, setSites] = useState<Site[]>([])
    let [companies, setCompanies] = useState<Company[]>([])
    let [dutyStatuses, setDutyStatuses] = useState<DutyStatus[]>([])
    let [stockProjectionResult, setStockProjectionResult] = useState<StockProjectionResult>(defaultStockProjectionResult)
    let [movementTypes, setMovementTypes] = useState<string[]>([])
    let [selectedMovementType, setSelectedMovementType] = useState<MovementType | null>(MovementType.Purchase)
    let [mots, setMots] = useState<string[]>([])
    let [selectedMot, setSelectedMot] = useState<string[] | null>(['InTank'])
    let [statuses, setStatuses] = useState<string[]>([])
    let [selectedStatus, setSelectedStatus] = useState<MovementStatus[] | null>([MovementStatus.Forecast])
    let [textFilter, setTextFilter] = useState<string | null>('')
    let [stockMode, setStockMode] = useState<StockProjectionMode>(StockProjectionMode.Sum)
    let [filterDatesOnError, setFilterDatesOnError] = useState<boolean>(false)
    let [simulations, setSimulations] = useState<StockSimulation[]>([])

    let [stockDateFilter, setStockDateFilter] = useState<string | null>(null)
    let [stockDateFilterMovements, setStockDateFilterMovements] = useState<MovementListItem[]>([])
    let [unit, setUnit] = useState<string | undefined>()
    let [displayedBlocks, setDisplayedBlocks] = useState<('stockTable' | 'stockChart' | 'movementTable' | 'messageTable')[]>(['stockTable', 'stockChart', 'movementTable'])
    let [warningFilter, setWarningFilter] = useState<string>('')
    let [nominationFilter, setNominationFilter] = useState<string>('')
    let [dealFilter, setDealFilter] = useState<string>('')
    let [counterpartys, setCounterpartys] = useState<Counterparty[]>([])
    let [sapTypesFilter, setSapTypesFilter] = useState<SapTypes[]>([])

    const inMovements = [MovementType.Purchase, MovementType.Transfer, MovementType.StatusChange, MovementType.Rebranding, MovementType.Borrow, MovementType.Gains, MovementType.Untriggered]
    const outMovements = [MovementType.Sale, MovementType.MktSale, MovementType.Transfer, MovementType.StatusChange, MovementType.Rebranding, MovementType.Loan, MovementType.Losses]

    const movementMots = ['InTank', 'Pipe', 'Road', 'Ship', 'Train', 'X Pump', 'Barge']
    const movementStatuses = ['Forecast', 'Planned', 'Actual']

    let userContext = UserContextContainer.useContainer()

    useEffect(() => {
        if (userContext.isLoggedIn) return
        setSites([])
        setProducts([])
        setDutyStatuses([])
        setCompanies([])
    }, [userContext.isLoggedIn])

    useEffect(() => {
        clearSimulation()
    }, [history.location])

    useEffect(() => {
        let units = products.filter(x =>
            stockFiltersStore.filters.productIds.includes(x.id)).map(x => x.unit).distinct()
        setUnit(units?.length == 1 ? units[0] : undefined)
    }, [stockFiltersStore.filters.productIds, products])

    useEffect(() => {
        setSapTypesFilter([])
    }, [displayedBlocks])

    let changeStockDateFilter = async (newDate: string | null, allMovements: MovementListItem[] | null = null) => {
        let filteredMovements = allMovements ?? movements

        setStockDateFilter(newDate)
        setStockDateFilterMovements(filteredMovements.filter(x => newDate === x.date))
    }

    let changeCompanies = (selectedCompaniesCode: string[]) => {
        stockFiltersStore.changeCompanies(companies, selectedCompaniesCode)
    }

    let changePeriod = (period) => {
        if (simulations.length > 0) {
            snackbars.warning(t('stock.label.clearSimulationWarning'))
            clearSimulation()
        }

        stockFiltersStore.changePeriod(period)
    }

    let deleteStockDateFilter = () => {
        setStockDateFilterMovements([])
        setStockDateFilter(null)
    }

    let changeSwitchState = (newState: string): void => {
        let movements: string[] = []
        if (newState === inValue)
            movements = inMovements.map(x => MovementTypeNameFromMovementType(x))
        else if (newState === outValue)
            movements = outMovements.map(x => MovementTypeNameFromMovementType(x))

        movements.unshift('Type')
        setMovementTypes(movements)
        setSelectedMovementType(null)
        setSwitchFilter(newState)
    }

    let getMotMovements = () => {
        setMots(movementMots)
        setSelectedMot(null)
    }

    let getMovementStatuses = () => {
        setStatuses(movementStatuses)
        setSelectedStatus(null)
    }

    let datesHasErrors = (stockFilters: StockFilters) => {
        if (!stockFiltersStore.filtersInitialized) return false
        if (!stockFilters.start || !stockFilters.end) return true

        let startDate = moment(stockFilters.start)
        let endDate = moment(stockFilters.end)
        let limitDate = moment("12/31/2018")

        if (startDate.format('MM/DD/YYYY') === endDate.format('MM/DD/YYYY')) return false

        return startDate.isAfter(endDate) || startDate.isBefore(limitDate)
    }

    let loadMovements = () => {
        movementQuerySubject.next({
            stockFilters: stockFiltersStore.filters.values,
            switchFilter,
            movementStatuses: selectedStatus,
            movementType: selectedMovementType,
            meansOfTransportation: selectedMot,
            textFilter,
            stockSimulations: simulations,
            warning: warningFilter,
            nomination: nominationFilter,
            deal: dealFilter,
            sapTypes: sapTypesFilter
        })
    }

    let loadStocks = () =>
        stockQuerySubject.next({ stockFilters: stockFiltersStore.filters.values, stockMode, stockSimulations: simulations })

    let load = async () => {
        if (!userContext.isLoggedIn || !hasClaim(Claims.StockManager)) return
        checkDates();
        loadStocks()
        loadMovements()
    }

    let checkDates = () => { if (datesHasErrors(stockFiltersStore.filters.values)) setFilterDatesOnError(true) }

    let setMovementLoaded = (movements: MovementListItem[]) => {
        let compareMovementsByDate = (a: MovementListItem, b: MovementListItem) => {
            if (!a.date) return -1
            if (!b.date) return 1
            if (moment(a.date).isBefore(moment(b.date))) return -1
            return 1
        }
        setMovements(movements.sort(compareMovementsByDate))
    }

    let addOrUpdateSimulation = (simulation: StockSimulation) => {
        let actualSimulations = [...simulations]
        let simulationIndex = actualSimulations.findIndex(x => x.date == simulation.date && x.movementType == simulation.movementType)
        if (simulationIndex >= 0)
            actualSimulations[simulationIndex] = simulation
        else
            actualSimulations.push(simulation)
        setSimulations(actualSimulations)
    }

    let removeSimulation = (date: string, movementType: string): boolean => {
        let actualSimulations = [...simulations]
        let simulationIndex = actualSimulations.findIndex(x => x.date == date && x.movementType == movementType)
        if (simulationIndex >= 0)
            actualSimulations.splice(simulationIndex, 1)
        else
            return false
        setSimulations(actualSimulations)
        return true
    }

    let clearSimulation = () => {
        setSimulations([])
    }

    function mapFlowType(sapSteps: SapFlowStepName[], movementType: MovementType | null): TransferFlowType | null {
        if (movementType === MovementType.StatusChange || movementType === MovementType.Transfer) {
            if (sapSteps.length === 1)
                return DirectTransfer.sapFlowType
            return StockTransferOrder.sapFlowType
        }
        return null
    }

    function mapToSapType(sapStepName: SapFlowStepName, movementType: MovementType | null, sapFlowType: TransferFlowType | null): SapTypes | null {
        switch (movementType) {
            case MovementType.Sale:
                {
                    switch (sapStepName) {
                        case SalesOrder.shortName:
                            return SalesOrder.sapType
                    }
                }
            case MovementType.Purchase:
                {
                    switch (sapStepName) {
                        case PurchaseOrder.shortName:
                            return PurchaseOrder.sapType
                        case PurchaseRelease.shortName:
                            return PurchaseRelease.sapType
                        case GoodReceipt.shortName:
                            return GoodReceipt.sapType
                    }
                }
            case MovementType.Rebranding:
                return Rebranding.sapType
            case MovementType.Gains:
                return Gains.sapType
            case MovementType.Losses:
                return Losses.sapType
            case MovementType.Transfer:
            case MovementType.StatusChange:
                {
                    if (sapFlowType === DirectTransfer.sapFlowType) {
                        return DirectTransfer.sapType
                    }
                    switch (sapStepName) {
                        case StockTransferOrder.shortName:
                            return StockTransferOrder.sapType
                        case TransferDeliveryNote.shortName:
                            return TransferDeliveryNote.sapType
                        case TransferGoodsIssue.shortName:
                            return TransferGoodsIssue.sapType
                        case GoodsTransfer.shortName:
                            return GoodsTransfer.sapType
                    }
                }
        }
        return null
    }

    function isReleaseCompleted(targetMovementId: Guid): boolean {
        let mvtInTable = movements.find(mvt => mvt.id === targetMovementId);
        return mvtInTable ? mvtInTable!.mainSapFlowListItem.steps.some(step => step.name === 'RE' && step.isCompleted) : false
    }

    useEffect(() => {
        checkDates()
        loadStocks()
    }, [stockFiltersStore.filters.values, stockMode, simulations])

    useEffect(() => {
        changeStockDateFilter(stockDateFilter, movements)
    }, [movements])

    useEffect(() => {
        let ifNoDateError = ({ stockFilters }: StockFiltersProp) => !datesHasErrors(stockFilters)
        let ifFieldsHasValues = ({ stockFilters }: StockFiltersProp) =>
            !!stockFilters.start && !!stockFilters.end
            && (!!stockFilters.productIds && stockFilters.productIds.length !== 0)
            && (!!stockFilters.sites && stockFilters.sites.length !== 0)
        let waitBeforeCall = () => hasFeature('StockProjectionMaterializationInApp') ? 1 : 500

        let stockQuerySubscription = stockQuerySubject.asObservable()
            .pipe(
                filter(ifNoDateError),
                filter(ifFieldsHasValues),
                map(createStockQuery),
                debounce(() => interval(waitBeforeCall())),
                switchMap(async query => {
                    try { return await api.get<StockProjectionResult>(query) }
                    catch { return Promise.resolve(undefined) }
                }),
                filter(x => x !== undefined),
                map(x => x as StockProjectionResult)
            )
            .subscribe(setStockProjectionResult)

        let movementQuerySubscription = movementQuerySubject.asObservable()
            .pipe(
                filter(ifNoDateError),
                filter(ifFieldsHasValues),
                map(createMovementQuery),
                debounce(() => interval(waitBeforeCall())),
                switchMap(async query => {
                    try { return await api.get<MovementListItem[]>(query) }
                    catch { return Promise.resolve(undefined) }
                }),
                filter(x => x !== undefined)
            )
            .subscribe(setMovementLoaded)

        changeSwitchState(inValue)
        getMotMovements()
        getMovementStatuses()

        return () => {
            movementQuerySubscription.unsubscribe()
            stockQuerySubscription.unsubscribe()
        }
    }, [])

    let movementTypeOptions = {
        ...defaultMovementTypeSelectionOptions,
        showInOutSwitch: true,
        showStatusChange: false,
        showRebranding: false,
        showBorrow: false,
        showGain: false,
        showLoan: false,
        showLosses: false,
        showUniqueRecurrentSwitch: false
    }

    let isStockChartOrTableDisplayed = () =>
        displayedBlocks.includes('stockChart') || displayedBlocks.includes('stockTable')

    let isMovementTableFullWidth = () => displayedBlocks.includes('movementTable')
        && !displayedBlocks.includes('messageTable')
        && (!displayedBlocks.includes('stockChart') || !displayedBlocks.includes('stockTable'))

    let loadCounterpartys = async () => {
        let counterpartysPromise = api.get<Counterparty[]>('stock/movement/counterparty')
        setCounterpartys(await counterpartysPromise)
    }

    let createStockQuery = ({ stockFilters, stockMode, stockSimulations }) =>
        `stock/projection?mode=${stockMode}&${constructFiltersStockProjectionQuery(stockFilters, stockSimulations)}`

    let createMovementQuery = ({
        stockFilters,
        switchFilter,
        movementStatuses,
        movementType,
        meansOfTransportation,
        textFilter,
        stockSimulations,
        warning,
        nomination,
        deal,
        sapTypes }
        : MovementRxFilters) => {
        let query = constructFiltersStockProjectionQuery(stockFilters, stockSimulations)
        query += `in=${switchFilter == inValue}&out=${switchFilter == outValue}`
        if (movementType !== null) query += `&movementType=${movementType}`
        movementStatuses?.forEach(x => query += `&movementStatuses=${x}`)
        meansOfTransportation?.forEach(x => query += `&meansOfTransportation=${x}`)
        if (textFilter !== null) query += `&textFilter=${textFilter}`
        if (hasFeature('MktSalesInMovementTable')) query += '&withMktSale=true'
        if (warning) query += `&warning=${warning}`
        if (nomination) query += `&nomination=${nomination}`
        if (deal) query += `&deal=${deal}`
        sapTypes?.forEach(x => query += `&sapFlowTypes=${x}`)

        return 'stock/movement?' + query
    }

    let constructFiltersStockProjectionQuery = (filters: StockFilters, simulation: StockSimulation[]) => {
        let query = ''
        let filterProperties = Object.keys(filters)
        let initializedFilters = filterProperties.filter(x => (filters[x] !== undefined &&
            filters[x] !== null && x !== 'companies' && x !== 'dutyStatuses' && x !== 'sites' && x !== 'productIds'
            && x !== 'allSites' && x !== 'allProductIds' && x !== 'allDutyStatuses' && x !== 'allCompanies'))

        initializedFilters.forEach(x => query += `${x}=${filters[x]}&`)
        filters.sites?.forEach(x => query += `site=${x}&`)
        filters.productIds?.forEach(x => query += `productIds=${x}&`)
        filters.dutyStatuses?.forEach(x => query += `dutyStatus=${x}&`)
        filters.companies?.forEach(x => query += `company=${x}&`)
        filters.types?.forEach(x => query += `type=${x}&`)

        query += constructSimulationQueryPart(simulation)

        return query
    }

    let loadFiltersElements = async () => {
        if (!userContext.isLoggedIn) return
        if (stockFiltersStore.filters?.dutyStatuses.length > 0) return

        let productsPromise = api.get<StockProduct[]>('stock/product')
        let companiesPromise = api.get<Company[]>('stock/company')
        let sitesPromise = api.get<Site[]>('stock/site')

        let products = await productsPromise
        let companies = await companiesPromise
        let sites = await sitesPromise
        let dutyStatuses = companies.map(x => x.dutyStatuses).reduce((a, b) => a.concat(b)).distinct()

        stockFiltersStore.loadFiltersElements(
            products.map(x => ({ value: x.id, text: x.code, group: x.productGroup ?? x.code })),
            sites.map(x => ({ value: x.code, text: x.name, group: x.siteGroup })),
            companies.map(x => ({ value: x.code, text: x.name })),
            dutyStatuses)

        setSites(sites)
        setProducts(products)
        setCompanies(companies)
        setDutyStatuses(dutyStatuses)
    }

    return {
        load, loadMovements, checkDates,
        inValue, outValue, movementTypes,
        showActualStockPopup, setShowActualStockDisplay,
        switchFilter, changeSwitchState,
        movements, setMovements,
        stockProjectionResult, setStockProjectionResult,
        stockFilters: stockFiltersStore.filters, loadFiltersElements,
        filterDatesOnError, setFilterDatesOnError,
        products, setProducts,
        stockMode, setStockMode,
        dutyStatuses, setDutyStatuses,
        companies, setCompanies,
        sites, setSites,
        selectedMovementType, setSelectedMovementType,
        simulations, addOrUpdateSimulation,
        removeSimulation, clearSimulation,
        changeStockDateFilter, stockDateFilter,
        deleteStockDateFilter, stockDateFilterMovements,
        unit, changeCompanies, changePeriod,
        displayedBlocks, setDisplayedBlocks,
        mapToSapType, mapFlowType, isReleaseCompleted,
        selectedMot, setSelectedMot, mots, setMots,
        selectedStatus, setSelectedStatus, statuses, setStatuses,
        setTextFilter, textFilter, movementTypeOptions, isMovementTableFullWidth, isStockChartOrTableDisplayed,
        warningFilter, setWarningFilter, nominationFilter, setNominationFilter,
        dealFilter, setDealFilter, counterpartys, loadCounterpartys, sapTypesFilter, setSapTypesFilter
    }
}

export let StockBoardContainer = createContainer(useStockBoard)