import { useEffect, useMemo, useState } from "react"
import moment from "moment"
import { createContainer } from "unstated-next"
import { Guid } from '../../../infrastructure/guid'
import { Company } from "../stockModels"
import { setInLocalStorage } from "../../../infrastructure/localStorage"
import { SupplyBalanceFilter } from "../../supplyBalance/supplyBalanceModels"
import { compareAndFixPeriod } from "../../common/components/datePicker/tools"
import { useFilter, mapValues } from "../../common/useFilter"
import { Item } from "../../common/components/select/types"

const defaultStartDate = () => moment.utc().startOf('month').format()
const defaultEndDate = () => moment.utc().startOf('month').add(1, 'M').add(-1, 'day').format()
const todayDate = () => moment.utc().format()

export function useStockBoardMultiFilters() {
    let companies = useFilter<string>()
    let dutyStatuses = useFilter<string>()
    let sites = useFilter<string>()
    let types = useFilter<string>()
    let productIds = useFilter<string>()

    let values = useMemo(() => {
        return mapValues({ companies, dutyStatuses, sites, types, productIds })
    }, [
        companies.selectedItems,
        dutyStatuses.selectedItems,
        sites.selectedItems,
        types.selectedItems,
        productIds.selectedItems])

    return { companies, dutyStatuses, sites, types, productIds, values }
}

function useStockBoardFilters() {

    let multiSelectFilters = useStockBoardMultiFilters()

    let [start, setStart] = useState<string | null>(null)
    let [end, setEnd] = useState<string | null>(null)
    let [monthRange, setMonthRange] = useState<number>(2)
    let [resellersRestrictionPercentage, setResellersRestrictionPercentage] = useState<number | null>(null)
    let [resellersRestrictionStart, setResellersRestrictionStart] = useState<string | null>(null)
    let [resellersRestrictionEnd, setResellersRestrictionEnd] = useState<string | null>(null)
    let [stockPercentHigh, setStockPercentHigh] = useState<boolean>(false)
    let [usefulStock, setUsefulStock] = useState<boolean>(false)

    let values = {
        ...multiSelectFilters.values,
        start,
        end,
        monthRange,
        resellersRestrictionPercentage,
        resellersRestrictionStart,
        resellersRestrictionEnd,
        stockPercentHigh,
        usefulStock
    }

    return {
        values,
        companiesFilter: multiSelectFilters.companies, companies: multiSelectFilters.values.companies,
        dutyStatusesFilter: multiSelectFilters.dutyStatuses, dutyStatuses: multiSelectFilters.values.dutyStatuses,
        sitesFilter: multiSelectFilters.sites, sites: multiSelectFilters.values.sites,
        typesFilter: multiSelectFilters.types, types: multiSelectFilters.values.types,
        productIdsFilter: multiSelectFilters.productIds, productIds: multiSelectFilters.values.productIds,
        start, setStart,
        end, setEnd,
        monthRange, setMonthRange,
        resellersRestrictionPercentage, setResellersRestrictionPercentage,
        resellersRestrictionStart, setResellersRestrictionStart,
        resellersRestrictionEnd, setResellersRestrictionEnd,
        stockPercentHigh, setStockPercentHigh,
        usefulStock, setUsefulStock
    }
}

function useStockFilters() {
    let filters = useStockBoardFilters()

    let [filtersInitialized, setFiltersInitialized] = useState<boolean>(false)

    useEffect(() => {
        if (filtersInitialized)
            setInLocalStorage('filters', filters)
    }, [filters.values])

    let GetSelectedItems = () => {
        let localStorageFilters = localStorage.getItem('filters')

        if (localStorageFilters) {
            let filterObj = JSON.parse(localStorageFilters)
            let filtersLength = Object.keys(filterObj).length

            if (filtersLength === 0) return

            let parseArray = x => Array.isArray(x) ? x : null

            const selectedDutyStatuses = parseArray(filterObj.dutyStatusesFilter?.selectedItems) ?? []
            filters.dutyStatusesFilter.setSelectedItems(selectedDutyStatuses)

            const selectedCompanies = parseArray(filterObj.companiesFilter?.selectedItems) ?? []
            filters.companiesFilter.setSelectedItems(selectedCompanies)

            const selectedProducts = parseArray(filterObj.productIdsFilter?.selectedItems) ?? []
            filters.productIdsFilter.setSelectedItems(selectedProducts)

            const selectedSites = parseArray(filterObj.sitesFilter?.selectedItems) ?? []
            filters.sitesFilter.setSelectedItems(selectedSites)

            filters.setStart(filterObj.start)
            filters.setEnd(filterObj.end)
            filters.setMonthRange(filterObj.monthRange)
            filters.setResellersRestrictionPercentage(filterObj.resellersRestrictionPercentage)
            filters.setResellersRestrictionStart(filterObj.resellersRestrictionStart)
            filters.setResellersRestrictionEnd(filterObj.resellersRestrictionEnd)
            filters.setStockPercentHigh(filterObj.stockPercentHigh)
            filters.setUsefulStock(filterObj.usefulStock)
        } else {
            filters.setStart(defaultStartDate())
            filters.setEnd(defaultEndDate())
            filters.setResellersRestrictionStart(todayDate())
            filters.setResellersRestrictionEnd(defaultEndDate())
        }
    }

    let setDefaultFiltersValues = (products: Item<Guid>[], sites: Item<string>[], companies: Item<string>[], dutyStatuses: string[]) => {
        if (dutyStatuses.length > 0) {
            filters.dutyStatusesFilter.setItems(dutyStatuses)
        }

        if (companies.length > 0) {
            filters.companiesFilter.setItems(companies)
        }

        if (products.length > 0) {
            filters.productIdsFilter.setItems(products)
        }

        if (sites.length > 0) {
            filters.sitesFilter.setItems(sites.sort((a, b) => a.text.localeCompare(b.text)))
        }

        filters.typesFilter.setItems(['Purchase', 'MktSale', 'Untriggered'])

        GetSelectedItems()
    }

    let loadFiltersElements = async (products: Item<Guid>[], sites: Item<string>[], companies: Item<string>[], dutyStatuses: string[]) => {
        if (filters?.dutyStatuses.length > 0) return

        setDefaultFiltersValues(products, sites, companies, dutyStatuses)
        setFiltersInitialized(true)
    }

    let impactFilters = (newFilters: SupplyBalanceFilter) => {
        filters.sitesFilter.setSelectedItems(newFilters.sites)
        filters.productIdsFilter.setSelectedItems(newFilters.productIds)
        filters.dutyStatusesFilter.setSelectedItems(newFilters.dutyStatuses)
        filters.companiesFilter.setSelectedItems(newFilters.companies)
        filters.setUsefulStock(newFilters.usefulStock)
    }

    let changeCompanies = (companies: Company[], selectedCompaniesCode: string[]) => {
        let companiesCode = companies.filter(x => selectedCompaniesCode.indexOf(x.code) > -1).map(x => x.code)
        filters.companiesFilter.setSelectedItems(companiesCode)
    }

    let changePeriod = (period) => {
        let newPeriod = compareAndFixPeriod(
            filters.values.start,
            period.startDate,
            filters.values.end,
            period.endDate
        )
        filters.setStart(newPeriod.start)
        filters.setEnd(newPeriod.end)
    }

    return {
        filters, filtersInitialized,
        loadFiltersElements, impactFilters,
        changeCompanies, changePeriod
    }
}
export let StockFiltersContainer = createContainer(useStockFilters)