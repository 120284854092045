import React from 'react'
import { createStyles, IconButton, Menu, MenuItem, Tooltip, Typography, Input, Checkbox, ListItemIcon } from '@material-ui/core'
import { muiOptions, MuiProps } from '../../../infrastructure/materialUiThemeProvider'
import { withStyles } from '@material-ui/styles'
import { allowedExtensions } from '../../common/fieldProps'

type MenuStyleProps = { button?: string }

type ItemValue = string | number

type MenuProps = {
    items: MenuItem[]
    selected?: ItemValue | ItemValue[],
    tooltip?: string,
    icon?: JSX.Element | undefined
    onChange?: (newValue: ItemValue | ItemValue[]) => void
    classesOverride?: MenuStyleProps
    disabled?: boolean
    anchor?: MenuAnchor | undefined
    multiSelect?: boolean
}

type MenuItem = {
    value?: string | number
    text: string
    icon?: string | JSX.Element
    textPadding?: string
    disabled?: boolean
    isUpload?: boolean
    onUpload?: (e) => void
    onClick?: () => void
}

type MenuAnchor = [
    anchor: Element | null,
    setAnchor: React.Dispatch<React.SetStateAction<Element | null>>
]

function _Menu({ id, classes, classesOverride, icon, items, selected, tooltip, onChange, anchor, multiSelect = false }: MenuProps & MuiProps) {
    let hasCustomAnchor = anchor !== undefined
    let [internalAnchor, setInternalAnchor] = React.useState(null)
    let [anchorElement, setAnchorElement] = hasCustomAnchor ? anchor! : [internalAnchor, setInternalAnchor]
    let open = Boolean(anchorElement)

    let handleOpen = (event) => {
        setAnchorElement(event.currentTarget);
    }

    let handleClose = () => {
        setAnchorElement(null)
    }

    let openInputFile = (name: string, isUpload?: boolean) => {
        isUpload && document.getElementById(name)?.click()
    }

    let handleChoice = (item: MenuItem) => {
        let itemValue: ItemValue = item.value ?? item.text
        if (multiSelect) {
            let selectedArray = Array.isArray(selected) ? (selected as ItemValue[]) : []
            let newSelected = selectedArray.includes(itemValue)
                ? selectedArray.filter(value => value !== (item.value ?? item.text))
                : [...selectedArray, itemValue]

            if (onChange && !item.disabled) {
                onChange(newSelected)
            }
        } else {
            handleClose()
            if (onChange && !item.disabled) {
                onChange(itemValue)
            }
        }

        if (item.onClick && !item.disabled) {
            item.onClick()
        }
    }

    return (
        <div>
            {!hasCustomAnchor ?
                !tooltip
                    ? <IconButton onClick={e => handleOpen(e)} className={classesOverride?.button ?? classes.button}>
                        {icon}
                    </IconButton>
                    : <Tooltip title={<Typography variant='body1'>{tooltip}</Typography>} placement='top'>
                        <IconButton onClick={e => handleOpen(e)} className={classesOverride?.button ?? classes.button}>
                            {icon}
                        </IconButton>
                    </Tooltip>
                : null
            }
            <Menu id={id} anchorEl={anchorElement} keepMounted open={open} onClose={_ => handleClose()}>
                {items.map(item =>
                    <MenuItem
                        key={item.value ?? item.text}
                        className={item.disabled ? classes.disabled : ''}
                        selected={Array.isArray(selected)
                            ? selected.includes((item.value ?? item.text) as never)
                            : selected === item.value}
                        onClick={() => handleChoice(item)}>
                        {multiSelect && item.icon != undefined && (
                            <ListItemIcon>
                                <Checkbox
                                    edge="start"
                                    checked={Array.isArray(selected) ? selected.includes((item.value ?? item.text) as never) : false}
                                    tabIndex={-1}
                                    disableRipple
                                />
                            </ListItemIcon>
                        )}
                        <div className={classes.line} onClick={_ => openInputFile(`file-upload-button-${item.text}`, item.isUpload)}>
                            {item.icon == null ? null
                                : <div className={classes.lineIcon}>
                                    {typeof item.icon === 'string' ? <img src={item.icon as string} /> : item.icon}
                                </div>}
                            <span style={{ paddingLeft: item.textPadding }}>{item.text}</span>
                            {item.isUpload && <Input className={classes.fileInput}
                                id={`file-upload-button-${item.text}`}
                                type='file' onChange={e => { item.onUpload?.(e); e.target.value = ""; }}
                                inputProps={allowedExtensions} />}
                        </div>
                    </MenuItem>)}
            </Menu>
        </div>
    )
}

let styles = theme =>
    createStyles({
        button: {
            padding: '8px'
        },
        line: {
            height: '2em',
            display: 'flex',
            alignItems: 'center'
        },
        lineIcon: {
            height: '1.3rem',
            width: '1.3rem',
            marginRight: '1em',
            '& > *': {
                height: '100%',
                width: '100%',
                color: 'rgba(0, 0, 0, 0.54)'
            }
        },
        fileInput: {
            display: 'none'
        },
        disabled: {
            opacity: '0.5',
            cursor: 'default',
            pointerEvents: 'none'
        }
    })

export let SelectMenu = React.memo(withStyles(styles, muiOptions)(_Menu))